<template>

    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module" />

        <div class="h-16 flex flex-row justify-start items-center px-10">

            <span class="text-2xl text-aux mr-auto">STOCK</span>
            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="h-auto flex flex-row justify-between items-center px-10">

            <span class="text-white font-semibold text-4xl mt-auto">{{stockData.stock || 0 | reduceBigNumbers()}} m2</span>

            <div class="h-full flex flex-col justify-between items-start">
                <span class="text-gray text-xl -mb-2">Entradas</span>
                <span class="text-white text-3xl font-semibold">{{stockData.entradas || 0 | reduceBigNumbers()}} m2</span>
            </div>

            <div class="h-full flex flex-col justify-between items-start">
                <span class="text-gray text-xl -mb-2">Ventas</span>
                <span class="text-white text-3xl font-semibold">{{stockData.ventas || 0 | reduceBigNumbers()}} m2</span>
            </div>

            <div class="h-full w-1/6">
            
                <img src="/img/factory.svg" class="h-16">

            </div>

        </div>

        <div class="h-16 flex flex-col justify-center items-start px-10">

            <span class="text-gray text-xl">Últimos 12 meses</span>
            <span class="text-gray text-xl -mt-2"><span class="text-lightblue">{{year}}</span> / {{past_year}}</span>

        </div>

        <div v-if="stockData" class="flex-1">

            <comparativechart :data="[stockData.graph.past, stockData.graph.current, stockData.rotation]" :values="true"/>

        </div>

    </div>

</template>

<script>
import comparativechart from '../components/charts/comparativechart.vue';
import loader from './loader.vue';
import moment from 'moment';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        comparativechart,
        loader
    },
    data(){
        return{
            chartdata:[],
            loading:false,
            stockData:false,
            year: moment(new Date(this.lastSyncro.date)).format('YYYY'),
            past_year: moment(new Date(this.lastSyncro.date)).subtract(1, 'years').format('YYYY'),
            firstTime: true
        }
    },
    methods:{
        
        load(){

            this.loading = true;

            this.axios.get('/stock').then(response => {

                this.stockData = response.data.data;
                this.loading = false;

            })

        }

    },
    mounted(){
        this.load();

        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>

<style>

</style>