<template>
    
    <div class="h-full w-full bg-body flex flex-row py-10 relative overflow-hidden vwtau">

        <div class="h-20 absolute top-0 right-0 mt-10 mr-10 flex flex-row justify-end items-center z-50 ">
            
            <div class="h-20 flex flex-col justify-center items-end mr-6">
                <span class="text-2xl text-white">{{today}}</span>
                <span class="text-2xl text-gray">{{hour}}</span>
            </div>
            
            <img src="/img/logotau.png" class="h-full border-r border-gray pr-4">

            <div class="h-full w-10 flex flex-col justify-center items-center ml-2">

                <div class="h-8 w-8 rounded-full bg-box flex flex-col justify-center items-center shadow cursor-pointer" @click="logout()">
                    <i class="mdi mdi-power-standby text-danger text-xl"></i>
                </div>

                <div v-if="user.admin == 1" class="h-8 w-8 rounded-full shadow bg-box mt-2 flex flex-col justify-center items-center cursor-pointer" @click="backoffice()">
                    <i class="mdi mdi-cogs text-white text-xl"></i>
                </div>

            </div>

        </div>

        <div class="h-full w-1/3 flex flex-col pl-10 pr-5 overflow-hidden">
        
            <div class="h-136 bg-module mb-8 shadow">
                <order-entry :lastSyncro="lastSyncro.Orders"/>
            </div>

            <div class="h-111 bg-module mb-8 shadow">
                <order-book :lastSyncro="lastSyncro.Orders"/>
            </div>

            <div class="h-128 bg-module mb-8 shadow">
                <thrown-lines :lastSyncro="lastSyncro.toPrepare"/>
            </div>

            <div class="flex-1 bg-module shadow">
                <infodailylines :lastSyncro="lastSyncro.Orders"/>
            </div>
        
        </div>

        <div class="h-full w-1/3 flex flex-col px-5 overflow-hidden">

            <div class="h-136 bg-module mb-8 shadow">
                <loadingorders :lastSyncro="lastSyncro.loadingOrders"/>
            </div>

            <div class="h-111 mb-8 flex flex-row">

                <div class="h-full w-full mr-8 bg-module shadow">
                    <containers :lastSyncro="lastSyncro.Container"/>
                </div>

                <div class="h-full w-full bg-module shadow">
                    <stock :lastSyncro="lastSyncro.Stock"/>
                </div>

            </div>

            <div class="flex-1 min-h-0 flex flex-col">

                <div class="h-158 flex flex-row">

                    <div class="h-158 w-full bg-module shadow mb-8 mr-8">
                        <deliverynotes :lastSyncro="lastSyncro.DeliveryNotes"/>
                    </div>

                    <div class="h-158 w-full bg-module shadow mb-8 relative">
                        <billing :data="billingData" :loading="billingLoading" :lastSyncro="lastSyncro.Sales"/>
                    </div>

                </div>

                <div class="flex-1 mt-8">

                    <div class="h-full w-full bg-module shadow overflow-hidden">
                        <billingevolution :lastSyncro="lastSyncro.Sales"/>
                    </div>

                </div>

            </div>

        </div>

        <div class="h-full w-1/3 pl-5 pr-10 flex flex-col overflow-hidden">
        
            <div class="h-244 w-468 mt-20">
                <worldmap />
            </div>

            <div class="flex-1 w-468 overflow-hidden">
                <europemap :bubbles="europeBubbles"/>
            </div>

        </div>

    </div>

</template>

<script>
    import { state, actions } from '@/store';
    import orderEntry from '../../components/orderEntry.vue';
    import orderBook from '../../components/orderBook.vue';
    import thrownLines from '../../components/thrownLines.vue';
    import infodailylines from '../../components/infodailylines.vue';
    import loadingorders from '../../components/loadingorders.vue';
    import containers from '../../components/containers.vue';
    import stock from '../../components/stock.vue';
    import deliverynotes from '../../components/deliverynotes.vue';
    import billing from '../../components/billing.vue';
    import deliverynotescharge from '../../components/deliverynotescharge.vue';
    import billingevolution from '../../components/billingevolution.vue';
    import worldmap from '../../components/worldmap.vue';
    import europemap from '../../components/europemap.vue';

    export default {
        name: 'Home',
        components:{
            orderEntry,
            orderBook,
            thrownLines,
            infodailylines,
            loadingorders,
            containers,
            stock,
            deliverynotes,
            billing,
            deliverynotescharge,
            billingevolution,
            worldmap,
            europemap
        },
        data(){
            return{
                today:false,
                hour: false,
                billingData: false,
                billingLoading: false,
                europeBubbles: false,
                lastSyncro:{
                    Orders: false,
                    DeliveryNotes: false,
                    Sales: false,
                    loadingOrders: false,
                    toPrepare:false,
                    Container:false,
                    Stock:false
                },
                firstTime: true
            }
        },
        methods:{
            logout(){
               
               this.axios.post('/logout').then(response => {
                    actions.setToken(null)
                    this.$router.push( {name:'Login'} )
                }) 
            },
            setTime(){

                this.today = this.$moment().format('DD-MM-YYYY'),
                this.hour = this.$moment().format('HH:mm')

            },
            load(){

                this.billingLoading = true;

                this.axios.get('/sale_by_sale_type/data')
                .then(response => {
                    
                    this.billingData = {
                        ...response.data.data.billing,
                        ...response.data.data.billingBubble
                    }

                    this.europeBubbles = {
                        margin: response.data.data.billingBubbleMargin,
                        mediumPrice: response.data.data.billingBubbleMediumPrice,
                        objective: response.data.data.billingBubbleObjective
                    }

                    this.billingLoading = false;

                })
                .catch(response => {

                    this.billingLoading = false;

                })

                this.axios.get('/last_synchronize_date/data').then(response => {

                    for (let index = 0; index < response.data.data.length; index++) {
                        
                        if(response.data.data[index].name == 'Orders'){
                            this.lastSyncro.Orders = response.data.data[index]
                        }

                        if(response.data.data[index].name == 'DeliveryNotes'){
                            this.lastSyncro.DeliveryNotes = response.data.data[index]
                        }

                        if(response.data.data[index].name == 'Sales'){
                            this.lastSyncro.Sales = response.data.data[index]
                        }

                        if(response.data.data[index].name == 'LoadingOrders'){
                            this.lastSyncro.loadingOrders = response.data.data[index]
                        }

                        if(response.data.data[index].name == 'ToPrepare'){
                            this.lastSyncro.toPrepare = response.data.data[index]
                        }

                        if(response.data.data[index].name == 'Container'){
                            this.lastSyncro.Container = response.data.data[index]
                        }

                        if(response.data.data[index].name == 'Stock'){
                            this.lastSyncro.Stock = response.data.data[index]
                        }

                    }

                })

            },
            backoffice(){
                this.$router.push({ name:'backoffice' })
            }
        },
        computed: {
            user() {
                return state.user;
            }
        },
        watch:{
        
        },
        mounted(){

            this.load();

            this.setTime();
            setInterval(() => {
                this.setTime();
            }, 1000);

            setInterval(() => {
                this.firstTime = false;
                this.load();
            }, state.timeRefresh);

        }
    }

</script>

<style scoped>

    .vwtau{
        width: 5760px !important;
        height: 2160px !important;
    }

</style>